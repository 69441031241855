<template>
    <div class="user-main">
        <div class="title">
            <span>密码修改</span>
            <el-divider></el-divider>
            <div class="biaodan">
                <el-form
                    :model="form"
                    :rules="rules"
                    ref="form"
                    label-width="100px"
                >
                   <el-form-item label="旧密码:" prop="oldpass">
                        <el-input type="password" v-model="form.oldpass" show-password autocomplete="off" placeholder="请输入原密码"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码:" prop="pass">
                        <el-input type="password" v-model="form.pass" show-password autocomplete="off" placeholder="请输入新密码"></el-input>
                    </el-form-item>
                    <el-form-item label="确认新密码:" prop="checkPass">
                        <el-input type="password" v-model="form.checkPass" show-password autocomplete="off" placeholder="请再次输入新密码"></el-input>
                    </el-form-item>
                    <div class="but">
                        <el-button type="primary" @click="onSubmitput('form')">确认修改</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { editPassword } from '@/api/login.js'
export default {
	created() {
		let userinfo = localStorage.getItem("userInfo");
        if (userinfo) {
			this.form.username = JSON.parse(userinfo).username;
        }
	},
    data() {
		// 密码一致验证
        var validatePass = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入新密码'));
            }
            if (value === '') {
                callback(new Error('请输入新密码'));
            } else {
                if (this.form.checkPass !== '') {
                    this.$refs.form.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请再次输入新密码'));
            }
            if (value === '') {
                callback(new Error('请再次输入新密码'));
            } else if (value !== this.form.pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            form: {
				username: "",
                oldpassword: "",
                password: "",
                confirmpassword: "",
            },
            rules: {
                oldpass: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' },
                ],
                pass: [
                    { required: true, validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { required: true, validator: validatePass2, trigger: 'blur' }
                ],
            }
        };
	},
	methods: {
		onSubmitput(form){
			this.$refs[form].validate((valid) => {
				if (valid) {
					editPassword(this.form).then(res => {
						if(res.success){
                            this.$message.success(res.message)
                            this.$refs['form'].resetFields()
                        }else{
                            this.$message.warning(res.message)
                        }
					})
				}
			})
		}
	},
};
</script>
<style lang="scss" scoped>
.user-main {
    padding: 25px;
    .title {
        font-size: 16px;
        color: #999;
    }
	.biaodan{
		width: 313px;
	}
    .el-divider--horizontal {
        margin: 13px 0 38px;
    }
    .but {
        margin-left: 100px;
        margin-top: 38px;
        .el-button--primary {
            width: 212px;
            height: 44px;
            background-color: #6EB400;
            border-radius: 3px;
            border-color: #6EB400;
        }
    }
}
</style>
